<template>
    <div class="extension">
        <div class="con-1">
            <img src="https://khome2.tuzuu.com/vita/logo.png" class="logo"/>
            <div class="title">
                推广奖励 | 成为UitaUita推广大使
            </div>

            <div class="box flex-between" style="padding: 0;position: relative">
                <div class="left-1 " >
                    <div class="box-title">
                        <span>我们是谁?</span>
                    </div>
                    <div class="info">
                        UitaUita创始于澳大利亚，早期为欧美用户提供个人客户和企业客户提供健康咨询与健康改善方案，2019年起和中国一流的基因科技公司和检测科技公司开展合作，为中国用户通过基因检测、代谢检测等精准手段，定制“一人一案”精准营养方案。
                    </div>
                </div>
                <img src="https://khome2.tuzuu.com/vita/chome_6.png" style="width: 49%" class="top-img"/>
            </div>

            <div class="box">
               <div class="box-title">
                   <span>我们提供什么服务?</span>
               </div>
                <div class="info">
                    我们根据你的基因，精准测出身体所缺少的营养，量身定制你专属的每日营养包。适合你的，才是最好的。
                </div>
            </div>

            <div class="box">
                <div class="box-title">
                    <span>我们的顾问</span>
                </div>
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#666666">
                    <van-swipe-item class="swipe-item" v-for="(item,index) in list" :key="index">
                       <div class="flex-between" style="align-items: end">
                           <van-image width="220px"  round :src="item.image" style="margin-top: 20px"/>
                           <div style="margin-left: 10px;width: 100%">
                               <div class="position">{{item.desc}}</div>
                               <div class="name">{{item.name}}</div>
                               <ul class="list">
                                   <li v-for="(info,index) in item.info" :key="index">
                                       {{info}}
                                   </li>
                               </ul>
                           </div>
                       </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
        <img src="https://khome2.tuzuu.com/vita/chome_7.png?v=1" />
        <img src="https://khome2.tuzuu.com/vita/chome_10.png" />

        <div class="con-2">
            <img src="https://khome2.tuzuu.com/vita/chome_8.png" />
        </div>
        <img src="https://khome2.tuzuu.com/vita/chome_9.png" />
    </div>
</template>

<script>
    export default {
        name: "",
        data(){
            return{
                list:[
                    {
                        image:'https://khome2.tuzuu.com/vita/preson_4.png',
                        name:'营养学博士',
                        desc:'布伦达·佩拉塔·罗梅罗',
                        info:[
                            '认证注册营养师、营养基因学和营养遗传学专家',
                            '5年临床营养评估、膳食计划和营养治疗经验' ,
                            '3年糖尿病患者营养治疗经验'
                        ]
                    },
                    {
                        image:'https://khome2.tuzuu.com/vita/preson_5.png',
                        name:'营养学博士',
                        desc:'凯瑟琳·斯·格瓦西奥',
                        info:[
                            '认证注册营养师、精准营养认证教练、运动营养认证教练',
                            '16年饮食与健康营养顾问专家经验'
                        ]
                    },
                    {
                        image:'https://khome2.tuzuu.com/vita/preson_6.png',
                        name:'麦吉尔大学心理学学士',
                        desc:'莎拉·索米安',
                        info:[
                            '加拿大整体营养研究所营养专家',
                            '预防性健康和慢性疾病营养治疗培训导师'
                        ]
                    }
                ]
            }
        },
        mounted() {

        }
    }
</script>

<style scoped lang="less">
    .extension{
        width: 100%;
        background-color: #F3F3F4;
        .con-1{
            width: 100%;
            padding: 20px 10px;
            background-color: #6B00AF;
            position: relative;
            box-sizing: border-box;
            .title{
                font-size: 23px;
                font-weight: 700;
                color: white;
                text-align: center;
                margin-bottom: 35px;
                margin-top: 55px;
            }
        }

        .box{
            width: 100%;
            border-radius: 10px;
            background-color: white;
            align-items: end;
            padding: 24px 15px  25px 14px;
            box-sizing: border-box;
            margin-top: 20px;

            .left-1{
                padding:16px 16px;
                height:100%;
                width: 51%;
                box-sizing:border-box;
                padding-right: 5px;
            }
            .box-title{
                font-size: 20px;
                color: #232323;
                position: relative;
                font-weight: 700;
                width: fit-content;
                margin-bottom: 11px;
                &:before{
                    position: absolute;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 7px;
                    bottom: 0;
                    left: 0;
                    background-color: #FF35A1;
                }
                span{
                    position: relative;
                }
            }
            .info{
                color: #666666;
                font-size: 12px;
                line-height: 22px;
            }
        }
        .my-swipe{
            padding-bottom: 30px;
            /deep/ .van-swipe__indicator--active{
                width: 30px;
                border-radius: 10px;
                transition: all 0.4s;
            }
            /deep/ .van-swipe__indicator {
                background-color: #D8D8D8;
            }
            .position{
                line-height: 19px;
                font-weight: 700;
                color: #3F3F3F;
                font-size: 15px;
                text-align: center;
            }
            .name{
                color: #666666;
                margin-top: 6px;
                font-size: 12px;
                text-align: center;
            }
            .list{
                margin-top: 13px;
                font-size: 11px;
                line-height: 20px;
                color: #666666;
                li{
                    display: flex;
                    &:before{
                        content: '-';
                        display: block;

                    }
                }
            }
        }

        .con-2{
            background-color: #6B00AF;
            padding: 20px 10px;
        }
        .top-img{
            position: absolute;
            height: 100%;
            right: 0;
            top: 0;
        }
    }
</style>